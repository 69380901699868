<template>
    <div class="addDemand"  id="addDemandCrossevent">
      <sm_header :title="'酒店'+addVenueType.title+'需求'" :smHleftEvent='closeWebview'></sm_header>
        <div class="publicHead">
          <div class="tips" v-if="tips">
            <p class="tipsTitle">注意事项</p>
            <div v-html="tips"></div>
          </div>
        </div>
        <component :is="addVenueType.type" ref="addDemand" ></component>
        <select-supplier ref="selectSupplier"></select-supplier>
        <div class="largeBtn3" @click="submitDemand">保存</div>
    </div>
</template>
<script>
    import addMeal from './components/addMeal'
    import addVenue from './components/addVenue'
    import addAccommodation from './components/addAccommodation'
    import addOther from './components/addOther'
    import selectSupplier from './components/selectSupplier'
    import {getCookie} from "tiny-cookie";
    import {SmartStorage} from "smart-core-util";
    export default {
        name: 'addDemand',
        components: {
            addMeal,
            addVenue,
            addAccommodation,
            addOther,
            selectSupplier
        },
        data () {
            return {
                addVenueType: {},
                isLoading: false,
                tips: '',
            }
        },
        created() {
            this.addType = this.$route.query.demandType
            this.$SmartStorage.set('demandType', this.addType)
            let selectType = {}
            switch (Number(this.addType)) {
                case 3:
                    selectType.title = '其他'
                    selectType.type = 'addOther'
                    selectType.itemType = 4
                    break
                case 2:
                    selectType.title = '住宿'
                    selectType.type = 'addAccommodation'
                    selectType.itemType = 3
                    break
                case 1:
                    selectType.title = '用餐'
                    selectType.type = 'addMeal'
                    selectType.itemType = 2
                    break
                case 0:
                    selectType.title = '会场'
                    selectType.type = 'addVenue'
                    selectType.itemType = 1
                    break
            }
            this.addVenueType = selectType
          this.getTips()
        },
        methods: {
            closeWebview () {
              this.$router.push("/");
            },
            async submitDemand () {
                if (this.isLoading) {
                   return
                }
                this.isLoading = true
                let venueData = this.$refs.addDemand.formProcessing()
                let supplierData = this.$refs.selectSupplier.formProcessing()
                if (!venueData || !supplierData) {
                    this.isLoading = false
                    return
                }
                let params = {
                    tenantId: this.$SmartStorage.get('eventData').tenantId || this.$cookie.get('tenant_id') || '',
                    proposalId: this.$SmartStorage.get('eventData').proposalId || '',
                    itemType: this.addVenueType.itemType,
                    itsSuppliersV2: supplierData
                }
                Object.assign(params, venueData)
                let res = await this.$service.saveItem(params)
                this.isLoading = false
                if (res && res.success) {
                    this.closeWebview()
                }
            },

          async getTips () {
            let params = {
              collection: "cfg-ivenue-tips",
              filter: {
                tenantCode: getCookie('tenant') || SmartStorage.get("tenant") || SmartStorage.get("tenant_code"),
                origin: 'addItem',
                demandType: { $in: [this.addVenueType.type] },
              }
            }
            let res = await this.$service.getsettings(params)
            if (res && res.success) {
              this.tips = res.content ? res.content.content : ''
            }
          }
        }
    }
</script>
<style scoped lang="scss">
    .addDemand {
      .publicHead {
        margin-top: 0.44rem;
        .headTitle {
          text-align: left;
          font-size: .2rem;
          margin-left: 0.25rem;
          position: relative;
        }
        .tips{
          font-size: 0.12rem;
          margin: 0.1rem 0.25rem 0 0.25rem;
          color: #90939A;
          background: #F5F5F5;
          padding: .14rem .18rem;
          border-radius: .04rem;
          .tipsTitle {
            font-size: .15rem;
            text-align: left;
          }
          div{
            margin-top: 0.1rem;
            text-align: left;
            font-size: .12rem;
            color: #909399;
          }
        }
      }
    }
</style>
