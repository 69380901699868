<template>
    <div>
        <div class="normalInputRow">
            <div class="input-container">
              <p class="input-label">摆台形式<span style="color: #d60012;">*</span></p>
              <div class="fitBox">
                <a :class="formData.itemName == item.val && 'selectFit'" @click="choseType(item, index, 'itemName')" v-for="(item, index) in btnList" :key="index">{{ item.txt }}</a>
              </div>
            </div>
        </div>
        <div class="normalInputRow" v-for="(item, index) in formList" :key="index">
          <iInput
              v-if="item.isShow"
              noCancel="false"
              :title="item.title"
              v-model="formData[item.vModel]"
              :placeholder="item.pla"
              :required="item.must"
              :type="item.type"
              :disabled="item.disabled"
              @click.native.stop='methodType(item)'
          ></iInput>
        </div>
        <div class="teaBreak">
          <div class="teaTitle">
            <span>茶歇</span>
            <switch-btn v-model="teaBreak[0].hasBreak" active-color="#3875FE"></switch-btn>
          </div>
          <div class="teaTips" v-if="teaBreak[0].hasBreak">
            <p>请填写此次会议所需的茶歇份数，建议不超过参会人数的70%</p>
          </div>
          <div class="teaCard" v-if="teaBreak[0].hasBreak">
            <add-tea v-if="teaBreak[0].hasBreak" ref="addTea0" :item="teaBreak[0]" :dtStart="formData.dtStart" :dtEnd="formData.dtEnd"></add-tea>
          </div>
        </div>
        <div class="teaBreak" v-if='["全天"].includes(dtTypeTxt) && ["roche", "pfizer"].includes(tenant)'>
          <div class="teaTitle">
            <span>茶歇</span>
            <switch-btn v-model="teaBreak[1].hasBreak" active-color="#3875FE"></switch-btn>
          </div>
          <div class="teaTips" v-if="teaBreak[1].hasBreak">
            <p>请填写此次会议所需的茶歇份数，建议不超过参会人数的70%</p>
          </div>
          <div class="teaCard" v-if="teaBreak[1].hasBreak">
            <add-tea v-if="teaBreak[1].hasBreak" ref="addTea1" :item="teaBreak[1]" :dtStart="formData.dtStart" :dtEnd="formData.dtEnd"></add-tea>
          </div>
        </div>
    </div>
</template>
<script>
    import {common} from "./common.js";
    import dateController from "@/components/datePicker/dateController";
    import switchBtn from "@/components/switchBtn/switchBtn";
    import vueFilter from "@/common/filter/vuefilter.js";
    import addTea from './addTea'
    import { Field } from 'vant';
    export default {
        name: 'addVenue',
        mixins: [common, dateController],
        components: {
            switchBtn,
            addTea,
            [Field.name]: Field,
        },
        data () {
            return {
                isHaveTea:false,
                isTeaNum: 1,
                visible:false,
                formData: {},
                btnList: [],
                teaList: [],
                dtTypeTxt: '', // 选中的时段
                // 茶歇信息
                teaBreak:[
                  {
                    hasBreak:false,
                    qtyBreak: null,
                    timeStartBreak:'',
                    timeEndBreak:''
                  },
                  {
                    hasBreak:false,
                    qtyBreak: null,
                    timeStartBreak:'',
                    timeEndBreak:''
                  }
                ],
                /**
                 * title 标签
                 * vModel 绑定值
                 * isShow 该选项是否根据其他条件判断显示---或者---单独判断显示不同样式选项
                 * must 是否必填
                 * disabled 是否只读
                 * method 点击执行函数
                 * parameter 函数执行参数
                 */
                formList: [
                    {title:'会场人数',pla: '会场人数',isShow: true,disabled: false,must: true,type: 'tel',vModel: 'qty1', method: '',parameter: ''},
                    {title:'开始时间',pla: '请选择开始时间',isShow: true,disabled: true,must: true,type: 'text',vModel: 'dtStart', method: 'selectTime',parameter: 'dtStart'},
                    {title:'结束时间',pla: '请选择结束时间',isShow: true,disabled: true,must: true,type: 'text',vModel: 'dtEnd', method: 'selectTime',parameter: 'dtEnd'},
                    {title:'备注',pla: '请填写备注信息',isShow: true,disabled: false,must: false,type: 'textarea',vModel: 'descr', method: '',parameter: ''},
                ],
            }
        },
        created(){
            this.$eventHub.$on('goVenue', () => {
                this.setVenueItem()
            })
            this.init()
        },
        methods: {
            async init() {
                this.getTimeFrame('venue')
                let eventData = this.$SmartStorage.get('eventData') || JSON.parse(this.$cookie.get('eventData'))
                this.dtTypeTxt = eventData?.extData?.dtTypeTxt || eventData.dtTypeTxt || ''
                this.btnList = await this.getTableTypeList("TableForm")
                let formData = this.$SmartStorage.get('venueItem')
                if (formData) {
                    this.$set(this.formData, 'qty1', formData.qty1)
                    this.$set(this.formData, 'itemId', formData.itemId)
                    this.$set(this.formData, 'dtStart', vueFilter.formatDate(this.isDate(formData.dtStart), 'yyyy/MM/dd hh:mm'))
                    this.$set(this.formData, 'dtEnd', vueFilter.formatDate(this.isDate(formData.dtEnd), 'yyyy/MM/dd hh:mm'))
                    this.$set(this.formData, 'descr', formData.descr)
                    this.$set(this.formData, 'itemName', formData.itemName)
                    this.teaBreak = formData.itsItemData.teaBreak.length ? formData.itsItemData.teaBreak : [
                      {
                        hasBreak:false,
                        qtyBreak: null,
                        timeStartBreak:'',
                        timeEndBreak:''
                      },
                      {
                        hasBreak:false,
                        qtyBreak: null,
                        timeStartBreak:'',
                        timeEndBreak:''
                      }
                    ]
                }
            },
            methodType (item) {
                if(item.method ==''){ return }
                this[item.method](item.parameter)
            },
            selectTime (params) {
                let startTime = vueFilter.formatDate(this.startTime,'yyyy/MM/dd');
                let endTime = vueFilter.formatDate(this.endTime,'yyyy/MM/dd');
                // 时间范围应该是会议开始前一天到会议结束后一天
                if (this.isTimeFrame) {
                    let tempStartTime = +new Date(startTime) - 24*60*60*1000;
                    let tempEndTime = +new Date(endTime) + 24*60*60*1000;
                    startTime = new Date(tempStartTime)
                    endTime = new Date(tempEndTime);
                }
                startTime = vueFilter.formatDate(startTime,'yyyy/MM/dd');
                endTime = vueFilter.formatDate(endTime,'yyyy/MM/dd');
                this.openSelectTime(startTime,endTime,this.formData[params] || vueFilter.formatDate(new Date()),params, 'yyyy/MM/dd hh:mm');
            },
            choseType (item, index, name) {
                this.$set(this.formData, name, item.val)
            },
            // 待提交数据处理
            formProcessing () {
                let toast = this.veryFilter();
                if (toast) {
                    this.$toast(toast);
                    return false;
                }
                let params = {
                    dtStart: this.formData.dtStart,//需求开始日期
                    dtEnd: this.formData.dtEnd,//需求结束日期
                    descr: this.formData.descr,//需求备注
                    qty1: Number(this.formData.qty1),//会场人数
                    itemName: this.formData.itemName,
                    dtTypeTxt: this.dtTypeTxt, // 选中的时段
                    unit1:'人', // 会议人数单位
                    itsItemData: {
                        refer:false,
                        teaBreak: []
                    },
                }

                if (this.teaBreak[0] && this.teaBreak[0].hasBreak) {
                    if (!this.$refs.addTea0.formProcessing()) {
                        return
                    }
                    let data = this.$refs.addTea0.formProcessing()
                    if (new Date(params.dtStart) > new Date(data.timeStartBreak)) {
                        toast = '茶歇开始时间需要小于会场开始时间！'
                    }
                    if (new Date(params.dtEnd) < new Date(data.timeEndBreak)) {
                        toast = '茶歇结束时间需要小于会场结束时间！'
                    }
                    params.itsItemData.teaBreak[0] = data
                } else {
                  params.itsItemData.teaBreak[0] = {
                    hasBreak:false,
                    qtyBreak: null,
                    timeStartBreak:'',
                    timeEndBreak:''
                  }
                }
                if (this.teaBreak[1] && this.teaBreak[1].hasBreak) {
                    if (!this.$refs.addTea1.formProcessing()) {
                        return
                    }
                    let data = this.$refs.addTea1.formProcessing()
                    if (new Date(params.dtStart) > new Date(data.timeStartBreak)) {
                        toast = '茶歇开始时间需要小于会场开始时间！'
                    }
                    if (new Date(params.dtEnd) < new Date(data.timeEndBreak)) {
                        toast = '茶歇结束时间需要小于会场结束时间！'
                    }
                    params.itsItemData.teaBreak[1] = data
                } else {
                  params.itsItemData.teaBreak[1] = {
                    hasBreak:false,
                    qtyBreak: null,
                    timeStartBreak:'',
                    timeEndBreak:''
                  }
                }
                if (toast) {
                    this.$toast(toast);
                    return false;
                }
                if (this.formData.itemId) {
                    params.itemId = this.formData.itemId
                }
                return params
            },
            veryFilter() {
                let toast = '';
                if (!this.formData.itemName) {
                    toast = '请选择摆台形式！';
                    return toast;
                }else if (!this.formData.qty1) {
                    toast = '请输入会场人数！';
                    return toast;
                }else if (!this.formData.dtStart) {
                    toast = '请选择开始时间！';
                    return toast;
                }else if (!this.formData.dtEnd) {
                    toast = '请选择结束时间！';
                    return toast;
                }
                let dtStart = `${this.formData.dtStart}:00`;
                let dtEnd = `${this.formData.dtEnd}:00`;
                if (dtStart > dtEnd) {
                    toast = '开始时间不能大于结束时间!';
                    return toast;
                }
                if (this.isHaveTea && !this.teaList.length) {
                    toast = '请添加茶歇!';
                    return toast;
                }
                return null;
            },
            setVenueItem () {
                let data = {
                    itsItemData: {
                        teaBreak: this.teaList || []
                    }
                }
                if (this.teaBreak[0] && this.teaBreak[0].hasBreak) {
                  data.itsItemData.teaBreak[0] = this.$refs.addTea0.formProcessing('noSave')
                } else {
                  data.itsItemData.teaBreak[0] = {
                    hasBreak:false,
                    qtyBreak: null,
                    timeStartBreak:'',
                    timeEndBreak:''
                  }
                }
                if (this.teaBreak[1] && this.teaBreak[1].hasBreak) {
                  data.itsItemData.teaBreak[1] = this.$refs.addTea1.formProcessing('noSave')
                } else {
                  data.itsItemData.teaBreak[1] = {
                    hasBreak:false,
                    qtyBreak: null,
                    timeStartBreak:'',
                    timeEndBreak:''
                  }
                }
                Object.assign(data, this.formData)
                this.$SmartStorage.set('venueItem', data)
            }
        },
    }
</script>
<style lang="scss">
    @import "addDemand";
</style>
