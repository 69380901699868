<template>
  <div class="addTea">
    <div class="normalInputRow " v-for="(item, index) in formList" :key="index">
      <iInput
          v-if="item.isShow"
          noCancel="false"
          :title="item.title"
          v-model="formTeaData[item.vModel]"
          :placeholder="item.pla"
          :required="item.must"
          :type="item.type"
          :disabled="item.disabled"
          @click.native.stop='methodType(item)'
      ></iInput>
    </div>
  </div>
</template>
<script>
import {common} from "./common.js";
import { Field } from 'vant';
import vueFilter from "@/common/filter/vuefilter.js";
import dateController from "@/components/datePicker/dateController";
export default {
  name: 'addTea',
  mixins: [common, dateController],
  props: {
    dtStart: {
      type: String,
      default: ''
    },
    dtEnd: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default(){
        return {}
      }
    },
  },
  components: {
    [Field.name]: Field,
  },
  data () {
    return {
      formTeaData: {},
      /**
       * title 标签
       * vModel 绑定值
       * isShow 该选项是否根据其他条件判断显示---或者---单独判断显示不同样式选项
       * must 是否必填
       * disabled 是否只读
       * method 点击执行函数
       * parameter 函数执行参数
       */
      formList: [
        {title:'茶歇人数',pla: '会场人数',isShow: true,disabled: false,must: true,type: 'tel',vModel: 'qtyBreak', method: '',parameter: ''},
        {title:'开始时间',pla: '请选择开始时间',isShow: true,disabled: true,must: true,type: 'text',vModel: 'timeStartBreak', method: 'selectTime',parameter: 'timeStartBreak'},
        {title:'结束时间',pla: '请选择结束时间',isShow: true,disabled: true,must: true,type: 'text',vModel: 'timeEndBreak', method: 'selectTime',parameter: 'timeEndBreak'},
      ],
      selectIndex: -1,
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      let formData = this.item
      if (formData) {
        this.$set(this.formTeaData, 'qtyBreak', formData.qtyBreak)
        this.$set(this.formTeaData, 'timeStartBreak', vueFilter.formatDate(this.isDate(formData.timeStartBreak), 'yyyy/MM/dd hh:mm'))
        this.$set(this.formTeaData, 'timeEndBreak', vueFilter.formatDate(this.isDate(formData.timeEndBreak), 'yyyy/MM/dd hh:mm'))
      }
    },
    methodType (item) {
      if(item.method ==''){ return }
      this[item.method](item.parameter)
    },
    selectTime (params) {
      if ((!this.dtStart || !this.dtEnd) && ['ipsen', 'roche', 'crossevent'].includes(this.tenant)) {
        this.$toast('请先选择会场时间！');
        return
      }
      let startTime = vueFilter.formatDate(this.startTime,'yyyy/MM/dd hh:mm');
      let endTime = vueFilter.formatDate(this.endTime,'yyyy/MM/dd hh:mm');
      // ipsen 修改会场时间，重置茶歇时间，茶歇时间不能超过会场时间
      if (['ipsen', 'roche', 'crossevent'].includes(this.tenant)) {
        startTime = vueFilter.formatDate(this.dtStart,'yyyy/MM/dd hh:mm');
        endTime = vueFilter.formatDate(this.dtEnd,'yyyy/MM/dd hh:mm');
      }
      let eventData = this.$SmartStorage.get('eventData') || JSON.parse(this.$cookie.get('eventData'))
      // 时间范围应该是会议开始前一天到会议结束后一天
      if (['自办会', '赞助会', '健康项目'].includes(eventData.typeDictVal) && ['crossevent'].includes(this.tenant)) {
        let tempStartTime = +new Date(startTime) - 24*60*60*1000;
        let tempEndTime = +new Date(endTime) + 24*60*60*1000;
        startTime = new Date(tempStartTime)
        endTime = new Date(tempEndTime)
      }
      startTime = vueFilter.formatDate(startTime,'yyyy/MM/dd');
      endTime = vueFilter.formatDate(endTime,'yyyy/MM/dd');
      this.openSelectTime(startTime,endTime,this.formTeaData[params] || vueFilter.formatDate(new Date()),params, 'yyyy/MM/dd hh:mm');
    },
    setSelectTime (target, time) {
      if (['timeStartBreak'].includes(target)) {
        if (new Date(this.dtStart) > new Date(time)) {
          this.$toast('开始时间需要小于会场开始时间！');
        } else if(this.formTeaData.timeEndBreak && time > this.formTeaData.timeEndBreak){
          this.$toast('开始时间需要小于结束时间！');
        }else{
          this.$set(this.formTeaData, 'timeStartBreak',time)
        }
      }
      if (['timeEndBreak'].includes(target)) {
        if (new Date(this.dtEnd) < new Date(time)) {
          this.$toast('结束时间需要小于会场结束时间！');
        } else if(this.formTeaData.timeStartBreak && time <= this.formTeaData.timeStartBreak){
          this.$toast('结束时间需要大于开始时间！')
        }else{
          this.$set(this.formTeaData, 'timeEndBreak',time)
        }
      }
    },
    formProcessing (type = '') {
      let toast = this.veryFilter();
      if (toast && !['noSave'].includes(type)) {
        this.$toast(toast);
        return false;
      }
      this.formTeaData.qtyBreak = Number(this.formTeaData.qtyBreak)
      this.formTeaData.hasBreak = true
      let params = this.formTeaData
      return params
    },
    veryFilter() {
      let toast = '';
      if (!this.formTeaData.qtyBreak) {
        toast = '请输入茶歇人数！';
        return toast;
      }else if (!this.formTeaData.timeStartBreak) {
        toast = '请选择开始时间！';
        return toast;
      }else if (!this.formTeaData.timeEndBreak) {
        toast = '请选择结束时间！';
        return toast;
      }
      return null;
    },
    editInit (data, i) {
      this.selectIndex = i
      if (i !== -1) {
        this.$set(this.formTeaData, 'qtyBreak', data.qtyBreak)
        this.$set(this.formTeaData, 'timeStartBreak', data.timeStartBreak)
        this.$set(this.formTeaData, 'timeEndBreak', data.timeEndBreak)
      } else {
        this.formTeaData = {}
      }
    }
  }
}
</script>
<style lang="scss">
.addTea{
  box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.15);
  border-radius: .04rem;
  padding: .1rem;
  margin-top: .1rem;
  background: #ffffff;
  position: relative;
  .normalInputRow {
    .input-container {
      padding: 0 !important;
    }
  }
}
</style>
